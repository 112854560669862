<template>
  <b-card>
    <pocu-header></pocu-header>
    <div id="charts-container" v-if="loadingDone && haveTableData">
        <b-row>
          <b-col cols="12" sm="12" md="5">
            <b-row>
              <b-col cols="12">
                <vue-apex-charts 
                  type="donut" 
                  height="400"
                  :options="charts.allPartnersApexPie.options" 
                  :series="charts.allPartnersApexPie.series"/>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <table class="parteneriListTable">
                  <tr v-for="(partener,index) in parteneri" :key="partener.nume">
                    <td class="table_dot"><span class="dot" :style="getDotColorStyle(index)"></span></td>
                    <td class="table_nume_partener">{{ partener.nume }}</td>
                  </tr>
                </table>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" sm="12" md="7">
            <b-row style="border-bottom:solid 1px rgb(187, 184, 184)">
              <b-col cols="6">
                <b-row>
                  <b-col cols="6">
                    <vue-apex-charts 
                      type="pie" 
                      height="150"
                      :options="charts.bugetApexPie.options" 
                      :series="charts.bugetApexPie.series"/>
                  </b-col>
                  <b-col cols="6">
                    <span class="buget_total_text text-darken-2">BUGET TOTAL PROIECT</span>
                    <span class="buget_total_value">{{ formatMoney(buget.total) }}</span>
                    <span class="finantare_nerambursabila_text text-darken-2">FINANȚARE NERAMBURSABILĂ</span>
                    <span class="finantare_nerambursabila_value">{{ formatMoney(buget.finantare_nerambursabila) }}</span>
                    <span class="contributie_proprie_text text-darken-2">CONTRIBUȚIE PROPRIE</span>
                    <span class="contributie_proprie_value">{{ formatMoney(buget.contributie_proprie) }}</span>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="3">

              </b-col>
              <b-col cols="3">
                <span class="procent_neramburasbil_mare" :style="getFontColorStyle(3)">
                  {{ procentFinantareNerambursabilaContributieProprie.nerambursabil }}%
                </span>
                <span class="procent_propriu_mare" :style="getFontColorStyle(0)">
                  {{ procentFinantareNerambursabilaContributieProprie.propriu }}%
                </span>
              </b-col>
            </b-row>
            <b-row style="margin-top:20px">
              <b-col cols="12">
                <h2>BUGET PARTENERI</h2>
                <h4>POCU {{currentProject.id_proiect}}</h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <vue-apex-charts 
                  type="bar" 
                  height="350" 
                  :options="charts.cheltuieliApexBar.options"
                  :series="charts.cheltuieliApexBar.series"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
    </div>
    <div v-else>
      <div id="no_project" v-if="!currentProject">
        <p>Nu ai nici un proiect. Poți adăuga un proiect nou folosind butonul de mai sus.</p>
      </div>
      <div v-else>
        <div id="no_content" v-if="loadingDone">
          <a class="go_to_upload" @click="goToUpload()">Te rugăm să încarci documentele necesare</a>
        </div>
        <div id="loading_animation" v-else>
          <b-spinner
            variant="primary"
          /> Se încarcă
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import axios from '@axios'
import {
  BRow, BCol, BCard, BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BFormCheckboxGroup, BIconNutFill, BSpinner 
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import PocuPieChart from './charts/PocuPieChart.vue'
import VueApexCharts from 'vue-apexcharts'
import PocuHeader from './parts/PocuHeader.vue'
import {mapState} from 'vuex'

// colors
const chartColors = {
  successColorShade: '#28dac6',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  greyBlue: '#377b95',
  darkBlue: '#22356e',
  primaryColorShade: '#836AF9',
  warningLightColor: '#FDAC34',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
  yellowColor: '#ffe800',
  warningColorShade: '#ffe802',
}

const newChartColors = {
  color1: '#cdcccc',
  color2: '#24b9aa',
  color3: '#377b95',
  color4: '#256076',
  color5: '#62c6ba',
  color6: '#22356e',
  color7: '#067f73',
  color8: '#314b9a',
  color9: '#2144ad'
}
let bugetTotalGlobal = 0
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    PocuPieChart,
    BSpinner,
    VueApexCharts,
    PocuHeader
  },
  data(){
    return {
      loadingDone: false,
      colorIndex: [],
      letterIndexParteneri: [],
      parteneri: {},
      buget: {},
      charts:{},
      cheltuieliApexBarValues : {},
      defaultCharts:{
        cheltuieliApexBar:{
          series:[{
            name: 'Cheltuieli Indirecte',
            data: [8, 8, 8, 8, 8, 8,]
          }, {
            name: 'Cheltuieli Directe',
            data: [92, 92, 92, 92, 92, 92,]
          }],
          options:{
            colors:['#377b95','#24b9aa'],
            legend:{
              position: 'top',
            },
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'flat',
                dataLabels:{
                  position: 'top',
                }
              },
            },
            dataLabels: {
              enabled: true,
              formatter: function(val){
                return val + "%"
              },
              offsetY: -20,
              style:{
                fontSize: '12px',
                colors: ['#304758']
              },
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: ['A', 'B', 'C', 'D', 'E', 'F',],
              position: 'bottom',
              axisBorder:{
                show:false,
              },
              axisTicks:{
                show: false,
              },
              labels:{
                show: false,
                rotate:0,
                maxHeight: 200,
                style:{
                  fontSize: '9px',
                  colors: ['#000000'],
                  cssClass: 'label_partener_tip_cheltuieli'
                },
              },
              crosshairs: {
                fill: {
                  type: 'gradient',
                  gradient: {
                    colorFrom: '#D8E3F0',
                    colorTo: '#BED1E6',
                    stops: [0, 100],
                    opacityFrom: 0.4,
                    opacityTo: 0.5,
                  }
                }
              },
              tooltip:{
                enabled: false,
              },
            },
            yaxis: {
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false,
              },
              labels: {
                show: false,
                formatter: function (val) {
                  return val + "%";
                }
              },
              title: {
                text: ''
              }
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              x: {
                formatter: (val) => {
                  return val
                },
              },
              y: {
                formatter: (val,data) => {
                  let num = this.cheltuieliApexBarValues[data.dataPointIndex][data.seriesIndex]
                  return num + " lei"
                  return val + "%"
                }
              }
            }
          }
        },
        bugetApexPie:{
          series: [],
          options:{
            tooltip: {
              enabled: true,
              y: {
                formatter: function(val) {
                  return new Intl.NumberFormat('ro-RO',{style:'currency',currency:'lei'}).format(val)
                },
                title: {
                  formatter: function (seriesName) {
                    return seriesName
                  }
                }
              }
            },
            labels: ['Contributie Proprie','Finantare Nerambursabila'],
            legend: {
              show: false
            },
            dataLabels:{
              enabled: false,
              formatter(val){
                return `${parseInt(val)}%`
              }
            },
            chart:{
              type:'pie'
            },
            colors:[chartColors.successColorShade,chartColors.greyBlue]
          },
        },
        allPartnersApexPie:{
          series : [],
          options:{
            tooltip: {
              enabled: true,
              y: {
                formatter: function(val) {
                  return new Intl.NumberFormat('ro-RO',{style:'currency',currency:'lei'}).format(val)
                },
                title: {
                  formatter: function (seriesName) {
                    return seriesName
                  }
                }
              }
            },
            legend: {
              show: false,
              position: 'bottom',
              fontSize: '14px',
              fontFamily: 'Montserrat',
            },
            dataLabels: {
              enabled: true,
              formatter(val,opts) {
                return `${parseInt(val)}%`
              },
              dropShadow: false,
            },
            chart:{
              type: 'donut',
            },
            colors:Object.values(newChartColors),
            plotOptions: {
              pie: {
                expandOnClick: false,
                donut: {
                  labels: {
                    show: true,
                    name: {
                      show: false,
                      fontSize: '2rem',
                      fontWeight: 700,
                      fontFamily: 'Montserrat',
                      formatter: function(val,opts){
                        return val
                      },
                    },
                    value: {
                      show: true,
                      fontSize: '1rem',
                      fontFamily: 'Montserrat',
                      formatter: function(val) {
                        // eslint-disable-next-line radix
                        return new Intl.NumberFormat('ro-RO',{style:'currency',currency:'lei'}).format(val)
                      },
                    },
                    total: {
                      show: true,
                      fontSize: '1.5rem',
                      label: 'Total',
                      formatter: function(w) {
                        let total = w.globals.seriesTotals.reduce((a, b) => {
                          return a + b
                        }, 0)
                        return new Intl.NumberFormat('ro-RO',{style:'currency',currency:'lei'}).format(total)//'31%'
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    }
  },
  computed: {
    haveTableData(){
      return this.parteneri.length > 0
    },
    ...mapState('pocu',{
      currentProjectId : 'project_id',
      allProjects: 'projects'
    }),
    currentProject(){
      return this.allProjects[this.currentProjectId]
    },
    procentFinantareNerambursabilaContributieProprie: function(){
      if (!this.buget) return {
        nerambursabil: 0,
        propriu: 0,
      }
      let procent = this.buget.total/100
      let procente = {
        nerambursabil: parseInt(this.buget.finantare_nerambursabila/procent),
      }
      procente.propriu = 100 - procente.nerambursabil
      return procente
    },
     parteneriLength: function(){
      return Object.keys(this.parteneri).length > 0
    },
  },
  mounted(){

  },
  watch: {
    currentProjectId(newVal,oldVal){
      this.getPrezentareBuget()
    }
  },
  methods: {
    maybeShowDecimals(value){
      if(Math.round(value) !== +value) {
        return +value
      }
      return Math.round(value)
    },
    goToUpload(){
      this.$router.push({name:'pocu-documentele-mele'})
    },
    resetCharts(){
      this.charts = JSON.parse(JSON.stringify(this.defaultCharts))
      this.buget = {}
      this.parteneri = {}
      this.letterIndexParteneri = {}
    },
    formatMoney(number,currency){
      currency = currency || 'lei'
      return new Intl.NumberFormat('ro-RO',{style:'currency',currency:currency}).format(number)
    },
    calculateAllPartnersPiePercentages(parteneri){
      let values = {}
      for (let [i,partener] of parteneri.entries()){
        values[partener.nume] = Number(partener.buget_total)
        //We can save some time and find the letter here as well
        this.letterIndexParteneri[partener.nume] = this.toLetters(i+1)
      }
      return values
    },
    calculatePartenersCheltuieliSeries(parteneri){
      let series = [{
            name: 'Cheltuieli Indirecte',
            data: []
          }, {
            name: 'Cheltuieli Directe',
            data: []
          }]
      for (let [i,partener] of parteneri.entries()){
        let percentage = partener.buget_total/100
        series[0].data.push(parseInt(partener.cheltuieli_indirecte/percentage))
        series[1].data.push(100 - parseInt(partener.cheltuieli_indirecte/percentage))
        this.cheltuieliApexBarValues[i] = [partener.cheltuieli_indirecte,partener.cheltuieli_directe]
      }
      return series
    },
    toLetters(num){
      "use strict";
      var mod = num % 26,
          pow = num / 26 | 0,
          out = mod ? String.fromCharCode(64 + mod) : (--pow, 'Z');
      return pow ? this.toLetters(pow) + out : out;
    },
    getDotColorStyle(index){
      return "background-color:" + newChartColors['color'+(index+1)]
    },
    getFontColorStyle(index){
      return "color:" + chartColors[this.colorIndex[index]]
    },
    chunkString(s,len){
      let curr = len, prev = 0;

      let output = [];

      while(s[curr]) {
        if(s[curr++] == ' ') {
          output.push(s.substring(prev,curr));
          prev = curr;
          curr += len;
        }
        else
        {
          let currReverse = curr;
          do {
              if(s.substring(currReverse - 1, currReverse) == ' ')
              {
                  output.push(s.substring(prev,currReverse));
                  prev = currReverse;
                  curr = currReverse + len;
                  break;
              }
              currReverse--;
          } while(currReverse > prev)
        }
      }
      output.push(s.substr(prev)); 
      return output;
    },
    getPrezentareBuget(){
      this.loadingDone = false
      this.resetCharts()
      axios
      .get('/pocu/getExecutiaBugetara',{
        params: {
          id_proiect: this.currentProjectId
        }
      })
      .then(response => {
        if (response.data.success){
          this.parteneri = response.data.parteneri
          this.buget = response.data.buget
          bugetTotalGlobal = this.buget.total
          let allPartnersPiePercentages = this.calculateAllPartnersPiePercentages(this.parteneri)
          let partenersCheltuieliSeries = this.calculatePartenersCheltuieliSeries(this.parteneri)
          let partenersCheltuieliCategories = []
          for (let partener of this.parteneri){
            partenersCheltuieliCategories.push(this.chunkString(partener.nume,30))
          }
          this.charts = Object.assign({},this.defaultCharts,{
            cheltuieliApexBar: Object.assign({},this.defaultCharts.cheltuieliApexBar,{
              series: partenersCheltuieliSeries,
              options: Object.assign({},this.defaultCharts.cheltuieliApexBar.options,{
                xaxis: Object.assign({},this.defaultCharts.cheltuieliApexBar.options.xaxis,{
                  // categories: Object.keys(allPartnersPiePercentages)
                  categories: partenersCheltuieliCategories,
               }),
              }),
            }),
            bugetApexPie: Object.assign({},this.defaultCharts.bugetApexPie,{
              series:[this.buget.contributie_proprie,this.buget.finantare_nerambursabila]
            }),
            allPartnersApexPie: Object.assign({},this.defaultCharts.allPartnersApexPie,{
              series:Object.values(allPartnersPiePercentages),
              // series:[44, 55, 41, 17, 15,44, 55, 41, 17, 15,44, 55, 41, 17, 15],
              options: Object.assign({},this.defaultCharts.allPartnersApexPie.options, {
                labels: Object.keys(allPartnersPiePercentages),
              }),
            })
          })
        }
        this.loadingDone = true
      })
    },
  },
  mounted(){
    
  },
  created(){
    //Create color index
    for (let i in chartColors){
      this.colorIndex.push(i)
    }
    if (this.currentProjectId){
      this.getPrezentareBuget()
    }
  }
}
</script>

<style scoped>
  #loading_animation{
    text-align:center;
  }
  .dot{
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }
  .table_letter{
    text-transform: uppercase;
    padding:2px 10px;
    font-weight:900;
    font-size:25px;
  }
  .table_dot{
    padding:2px 10px;
  }
  .table_nume_partener{
    padding: 2px 10px;
  }
  .parteneriListTable{
    border: none;
  }
  .parteneriListTable tr{
    border: none;
  }
  .parteneriListTable td{
    white-space: normal;
  }
  .buget_total_text{
    display:block;
    font-size:12px;
    /* color:black; */
    margin-bottom:5px;
    font-weight:700
  }
  .buget_total_value{
    display:block;
    font-size:16px;
    margin-bottom:5px;
  }
  .finantare_nerambursabila_text{
    display:block;
    font-size:12px;
    /* color:black; */
    margin-bottom:5px;
    font-weight:700
  }
  .finantare_nerambursabila_value{
    display:block;
    font-size:16px;
    margin-bottom:5px;
  }
  .contributie_proprie_text{
    display:block;
    font-size:12px;
    /* color:black; */
    margin-bottom:5px;
    font-weight:700
  }
  .contributie_proprie_value{
    display:block;
    font-size:16px;
    margin-bottom:5px;
  }
  .procent_neramburasbil_mare, .procent_propriu_mare{
    display:block;
    font-size:30px;
  }
  .procent_neramburasbil_mare{
    margin-top:20px;
    text-decoration: underline;
    text-decoration-color: rgb(187, 184, 184);
    text-decoration-thickness: 2px;
    text-underline-offset: 5px;
  }
  #no_content{
    text-align:center;
  }
  #no_project{
    text-align:center;
  }
  .go_to_upload{
    color:#43eed5;
    text-decoration:underline;
  }
</style>
<style>
.label_partener_tip_cheltuieli{
    background-color:red;
  }
.apexcharts-legend-text{
  color:inherit !important;
}
.apexcharts-data-labels text, .apexcharts-text.apexcharts-datalabel-value, .apexcharts-text.apexcharts-datalabel-label{
  fill:#373d3f;
}
body.dark-layout .apexcharts-data-labels text, body.dark-layout .apexcharts-text.apexcharts-datalabel-value, body.dark-layout .apexcharts-text.apexcharts-datalabel-label{
  fill:#b4b7bd;
}
</style>