<script>
import { Pie, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
export default {
  extends: Pie,
  mixins: [reactiveProp],
  props: {
    data: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  watch: {
    chartData:{
      handler: function(){
      },
      deep: true,
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  },
}
</script>
